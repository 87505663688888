// brand colors
$blue-50: #d9ecf9;
$blue-100: #bde5ff;
$blue-200: #a1d9ff;
$blue-300: #76c8ff;
$blue-400: #5cbdff;
$blue-500: #33adff;
$blue-600: #2e9de8;
$blue-700: #247bb5;
$blue-800: #1c5f8c;
$blue-900: #15496b;

$club-50: #fbe8ff;
$club-100: #f2b8ff;
$club-200: #ec95ff;
$club-300: #e465ff;
$club-400: #de47ff;
$club-500: #d619ff;
$club-600: #c317e8;
$club-700: #9812b5;
$club-800: #760e8c;
$club-900: #5a0b6b;

$green-50: #edfff9;
$green-100: #c8ffed;
$green-200: #adffe4;
$green-300: #88ffd8;
$green-400: #71ffd1;
$green-500: #4dffc5;
$green-600: #46e8b3;
$green-700: #37b58c;
$green-800: #2a8c6c;
$green-900: #206b53;

$purple-50: #ebe6f3;
$purple-100: #c1b0da;
$purple-200: #a38ac9;
$purple-300: #7a54b0;
$purple-400: #6033a1;
$purple-500: #380089;
$purple-600: #300074;
$purple-700: #280061;
$purple-800: #20004e;
$purple-900: #19003e;

// neutral colors
$gray-50: #fdfdfd;
$gray-100: #f8f7f8;
$gray-200: #f4f4f5;
$gray-300: #f0eef1;
$gray-400: #e3e1e6;
$gray-500: #b7b2bd;
$gray-600: #8f859b;
$gray-700: #635a70;
$gray-800: #453954;
$gray-900: #31283c;

$violet-50: #eae8ee;
$violet-100: #bfb7cb;
$violet-200: #a094b2;
$violet-300: #75648e;
$violet-400: #5a4579;
$violet-500: #311757;
$violet-600: #2d154f;
$violet-700: #23103e;
$violet-800: #1f0c38;
$violet-900: #160829;

//system colors
$green-light: #d2ead3;
$green: #66b86a;
$green-dark: #45a949;

$yellow-dark: #fff3c2;
$yellow: #ffd52f;
$yellow-light: #fc0;

$orange-light: #ffe9cd;
$orange: #ffb454;
$orange-dark: #ffa42e;

$red-light: #e2a393;
$red: #ba4a2e;
$red-dark: #ab2200;
