@use "ui/colors";
@use "mixins/breakpoint";

.welcome {
  display: flex;
  justify-content: center;
  min-height: 100dvh;
  padding: 24px 16px;
  color: colors.$gray-50;
  background-color: colors.$violet-700;

  @include breakpoint.tablet {
    padding: 40px 32px;
  }
}

.layoutWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include breakpoint.tablet {
    gap: 72px;
  }

  @include breakpoint.desktop {
    gap: 0;
  }
}

.header {
  display: flex;
  justify-content: center;

  @include breakpoint.desktop {
    justify-content: flex-start;
  }
}

.logo {
  width: 148px;
  height: 30px;

  @include breakpoint.tablet {
    width: 184px;
    height: 40px;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;

  @include breakpoint.desktop {
    margin: auto 0;
  }
}

.image {
  display: none;

  @include breakpoint.desktop {
    display: flex;
    align-self: center;
  }
}
