@use "variables/breakpoint";

@mixin tablet {
  @media (min-width: breakpoint.$tablet) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: breakpoint.$laptop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: breakpoint.$desktop) {
    @content;
  }
}
