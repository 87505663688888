@use "ui/colors";
@use "mixins/breakpoint";

.countryList {
  width: 328px;

  @include breakpoint.tablet {
    width: 600px;
    margin: 0 auto;
  }
}

.title {
  text-align: center;

  @include breakpoint.desktop {
    text-align: left;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;

  @include breakpoint.tablet {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  @include breakpoint.desktop {
    justify-content: flex-start;
  }
}

.link {
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 16px;
  text-decoration: none;
  color: inherit;
  background-color: colors.$violet-600;

  &:hover {
    background-color: colors.$violet-400;
  }
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
}
